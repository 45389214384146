import React from 'react'
import { Link } from 'gatsby'
import Layout from '~/components/layout'
import Button from '~/components/button'

const Success = () => {
  return (
    <Layout>
      <div className="h-full">
        <div className={`grid grid-cols-12 container laptop:m-auto`}>
          <div className="col-start-1 col-end-13 min-h-screen text-center push-content-alt">
            <img
              className="mx-auto pt-10"
              width={60}
              src="/success.png"
              alt="success checkmark"
            />
            <h1 className="my-2">Paiement reçu.</h1>
            <p className="mb-4">Merci pour votre confiance.</p>
            <div>
              <div className="mx-auto largeMobile:inline-flex gap-x-2">
                <Link to="/">
                  <Button label="Retour à l'accueil" />
                </Link>
                <Link to="/paiement">
                  <Button label="Payer une autre facture" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Success
